<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <div class="container-fluid">
          <ul class="breadcrumb ProximaNovaRegular" style="margin-top: 12px">
            <li>
              <span>Demo</span>
            </li>
            <li>
              <span>India</span>
            </li>
            <li
              v-if="activeFrom === 1"
              :class="activeFrom === 1 ? 'ProximaNovaBold' : ''"
            >
              Platform
            </li>
            <li v-else>
              <span @click="activeFrom = 1">Platform</span>
            </li>
            <li
              :class="activeFrom === 2 ? 'ProximaNovaBold' : ''"
              v-if="activeFrom === 2"
            >
              DataSource
            </li>
            <li v-else-if="activeFrom === 3">
              <span @click="activeFrom = 2">DataSource</span>
            </li>
            <li
              :class="activeFrom === 3 ? 'ProximaNovaBold' : ''"
              v-if="activeFrom === 3"
            >
              KPI
            </li>
          </ul>
        </div>
        <div class="container mb-5" style="margin-top: 28px">
          <div
            class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center
            "
          >
            <div class="col-sm-12 col-md-7 col-xs-12 col-lg-7">
              <w-multi-step-bar
                :stepName="stepName"
                :activeState="activeFrom"
              ></w-multi-step-bar>
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="margin-top: 80px; padding-left: 52px"
          v-if="activeFrom === 1"
        >
          <w-tab
            :tabName="activeTab"
            :tabs="tabs"
            @activeTab="changeTab($event)"
          >
          </w-tab>
        </div>
        <div class="w-100" v-if="activeFrom === 1">
          <div
            class="container-fluid tab-panel"
            style="margin-top: 36px; padding-left: 52px"
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ showConnectors: activeTab === tab.category }"
          >
            <div
              class="card w-100"
              v-for="(tabContent, index) in tab.tabContent"
              :key="index"
            >
              <div
                class="card-header font-large ProximaNovaBold"
                style="
                  padding: 0.75rem 1.5rem;
                  background: transparent;
                  border: 0px !important;
                "
              >
                {{ tabContent.title }}
              </div>
              <div class="card-body">
                <div>
                  <w-connector
                    :addActiveClass="false"
                    :connectors="tabContent.connectors"
                    :title="tabContent.title"
                    :selectedConnectorsList="selectedConnectors"
                    @addPlatform="addPlatform($event)"
                  >
                  </w-connector>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="margin-top: 80px; padding-left: 52px"
          v-if="activeFrom === 2"
        >
          <w-tab
            :tabName="activeTab"
            :tabs="categorizedSelectedConnectors"
            @activeTab="changeTab($event)"
          >
          </w-tab>
        </div>
        <div
          class="w-100"
          v-if="activeFrom === 1 && activeTab === 'Additional Details'"
        >
          <w-scenarioplanning> </w-scenarioplanning>
        </div>
        <div class="w-100" v-if="activeFrom === 2">
          <div
            class="container-fluid tab-panel"
            style="margin-top: 36px; padding-left: 52px"
            v-for="(tab, index) in categorizedSelectedConnectors"
            :key="index"
            :class="{ showConnectors: activeTab === tab.category }"
          >
            <div
              class="card w-100"
              v-for="(tabContent, index) in tab.tabContent"
              :key="index"
            >
              <div
                class="card-header font-large ProximaNovaBold"
                style="
                  padding: 0.75rem 1.5rem;
                  background: transparent;
                  border: 0px !important;
                "
              >
                {{ tabContent.title }}
              </div>
              <div class="card-body">
                <div>
                  <w-connector
                    :addActiveClass="true"
                    :connectors="tabContent.connectors"
                    :title="tabContent.title"
                    :selectedConnectorsList="selectedConnectors"
                  >
                  </w-connector>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 py-4" v-if="activeFrom === 3">
          <div
            class="container-fluid"
            style="margin-top: 36px; padding-left: 52px"
          >
            <div class="col-md-12 col-lg-12">
              <div class="card w-100">
                <div class="row">
                  <div class="col-md-4 colo-lg-4">
                    <div class="w-100 p-5 m-5">
                      <img
                        src="@/assets/Icons/kpi.webp"
                        alt="KPI"
                        height="100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-8 col-lg-8">
                    <div class="w-100 row" style="height: 100%">
                      <div
                        class="w-100 d-flex px-5"
                        style="
                          height: 35%;
                          justify-content: start;
                          align-items: flex-end;
                        "
                      >
                        <span class="ProximaNovaBold" style="font-size: x-large"
                          >Define KPI</span
                        >
                      </div>
                      <div
                        class="col-md-6 colo-lg-6 d-flex justify-center"
                        style="height: 40%"
                      >
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown
                            :options="kpidropdown"
                            :placeHolder="'Select'"
                            :labelText="'KPI'"
                            class="mr-3"
                            @input="selectedKPi($event)"
                          ></w-dropdown>
                        </div>
                      </div>
                      <div
                        class="col-md-6 colo-lg-6 d-flex justify-center"
                        style="height: 40%"
                      >
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown
                            :options="marketDropdown"
                            :labelText="'Market Type'"
                            class="mr-5"
                            @input="selectMarket($event)"
                          ></w-dropdown>
                        </div>
                      </div>
                      <div class="w-100 pr-5" style="height: 25%">
                        <div
                          class="row mr-2 pr-2"
                          style="float: right"
                          :class="{ changeColor: isDisable }"
                        >
                          <w-button
                            :buttonLoader="'normal'"
                            :buttonText="'Create Plan'"
                            :class="{ resetPasswordLink: isDisable }"
                            :isDisable="isDisable"
                            @buttonClicked="createPlan"
                          >
                          </w-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid w-100" v-if="activeFrom < 3">
          <div class="row pt-5 pb-5 mr-2" style="float: right">
            <w-button
              :buttonLoader="'normal'"
              :buttonText="'Continue'"
              :isDisable="disableConnectors"
              :class="{ resetPasswordLink: disableConnectors }"
              @buttonClicked="nextToSource"
            >
            </w-button>
          </div>
        </div>
        <!--<div class="w-100 d-flex justify-center align-center">
            <Learning />
        </div>-->
        <solution-modal ref="solutionModal" @progressDone="doneProgress" />
      </main>
    </div>
  </div>
</template>
<script>
import Connector from "@/components/AIMO-AI/Connector.vue";
import MultiStepProgressBar from "@/components/Solutions/MultiStepProgressBar.vue";
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/Tab.vue";
import Dropdown from "@/widgets/Dropdown.vue";
// import Learning from "@/components/AIMO-AI/Learning.vue"
import SolutionModal from "@/components/Modal/SolutionModal.vue";
import AdditionalDetails from "@/pages/Scenario/AdditionalDetails.vue";
export default {
  props: [],
  components: {
    "w-connector": Connector,
    "w-multi-step-bar": MultiStepProgressBar,
    "w-button": Button,
    "w-tab": Tab,
    "w-dropdown": Dropdown,
    "solution-modal": SolutionModal,
    // Learning
    "w-scenarioplanning": AdditionalDetails,
  },
  data() {
    return {
      kpidropdown: [
        { text: "Sales", id: "sales" },
        { text: "Leads", id: "leads", disable: true },
        { text: "Profit Margin", id: "Profit Margin", disable: true },
        { text: "Volume", id: "volume", disable: true },
        { text: "Cart Value", id: "Cart Value", disable: true },
      ],
      marketDropdown: [
        { text: "All India", id: "allindia" },
        { text: "HSM", id: "hsm", disable: true },
        { text: "AP", id: "AP", disable: true },
        { text: "TN", id: "TN", disable: true },
        { text: "KER", id: "ker", disable: true },
        { text: "KA", id: "KA", disable: true },
      ],
      selectedMarket: "",
      selectedKPI: "",
      activeTab: "Digital Media",
      activeFrom: 1,
      orderSubCategory: [],
      selectedConnectors: [],
      categorizedSelectedConnectors: [],
      stepName: ["Platform", "DataSource", "KPI"],
      tabs: [
        {
          category: "Digital Media",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "google-anal",
                  role: "DM google-anal",
                  connectionId: 270,
                },
                {
                  name: "Google Trends",
                  image: "Google Trends",
                  role: "DM Google Trends",
                  connectionId: 663,
                },
                {
                  name: "Google Sheets",
                  image: "Google_Sheets",
                  role: "WA google sheets",
                  connectionId: 267,
                },
                {
                  name: "Adobe Analytics (Service)",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics (Service)",
                  connectionId: null,
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics",
                  connectionId: null,
                },
                {
                  name: "Adobe Analytics(WSSE)",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics(WSSE)",
                  connectionId: null,
                },
                {
                  name: "Convert",
                  image: "Convert",
                  role: "WEB ANAlytics Convert",
                  connectionId: null,
                },
                {
                  name: "Etracker",
                  image: "Etracker",
                  role: "WEB ANAlytics Etracker",
                  connectionId: null,
                },
                {
                  name: "Google Analytics(Oauth)",
                  image: "Google Analytics(Oauth)",
                  role: "WEB ANAlytics Google Analytics(Oauth)",
                  connectionId: null,
                },
                {
                  name: "Google Analytics(Service)",
                  image: "Google Analytics(Oauth)",
                  role: "WEB ANAlytics Google Analytics(Service)",
                  connectionId: null,
                },
                {
                  name: "IBM Analytics",
                  image: "IBM Analytics",
                  role: "WEB ANAlytics IBM Analytics",
                  connectionId: null,
                },
                {
                  name: "Magnite Performance",
                  image: "Magnite Performance",
                  role: "WEB ANAlytics Magnite Performance",
                  connectionId: null,
                },
                {
                  name: "Mixpanel",
                  image: "Mixpanel",
                  role: "WEB ANAlytics Mixpanel",
                  connectionId: null,
                },
                {
                  name: "Optimizely",
                  image: "Optimizely",
                  role: "WEB ANAlytics Optimizely",
                  connectionId: null,
                },
                {
                  name: "Piwik Pro",
                  image: "Piwik Pro",
                  role: "WEB ANAlytics Piwik Pro",
                  connectionId: null,
                },
                {
                  name: "Similar Web",
                  image: "Similar Web",
                  role: "WEB ANAlytics Similar Web",
                  connectionId: null,
                },
                {
                  name: "Site Impact",
                  image: "Site Impact",
                  role: "WEB ANAlytics Site Impact",
                  connectionId: null,
                },
                {
                  name: "Webtrekk",
                  image: "Webtrekk",
                  role: "WEB ANAlytics Webtrekk",
                  connectionId: null,
                },
                {
                  name: "Webtrends",
                  image: "Webtrends",
                  role: "WEB ANAlytics Webtrends",
                  connectionId: null,
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
            {
              title: "Social Insights",
              connectors: [
                {
                  name: "Facebook Insights",
                  image: "facebook",
                  role: "DM Facebook Page Insights",
                  connectionId: 690,
                },
                {
                  name: "Instagram Insights",
                  image: "instagram",
                  role: "DM Instagram Insights",
                  connectionId: 214,
                },
                {
                  name: "YouTube Insights",
                  image: "youtube",
                  role: "DM Youtube Insights",
                  connectionId: 284,
                },
                {
                  name: "LinkedIn Insights",
                  image: "linkedin",
                  role: "DM linkedin insights",
                  connectionId: 319,
                },
                {
                  name: "Twitter Insights",
                  image: "twitter",
                  role: "DM Twitter Insights",
                  connectionId: 462,
                },
                {
                  name: "Google Ads Keyword Planner",
                  image: "google",
                  role: "DM Google Ads Keyword Planner",
                  connectionId: 741,
                },
                {
                  name: "Google Sheets",
                  image: "Google_Sheets",
                  role: "DM google sheets",
                  connectionId: 267,
                },
              ],
            },
            {
              title: "Ad Server",
              connectors: [
                {
                  name: "Adform Buyer",
                  image: "Adform Buyer",
                  role: "ADS Adform Buyer",
                  connectionId: null,
                },
                {
                  name: "Adform Master",
                  image: "Adform Buyer",
                  role: "ADS Adform Master",
                  connectionId: null,
                },
                {
                  name: "Flash Talking",
                  image: "flashtalking",
                  role: "ADS flashtalking",
                  connectionId: null,
                },
                {
                  name: "Gemius Adreal",
                  image: "Gemius Adreal",
                  role: "ADS Gemius Adreal",
                  connectionId: null,
                },
                {
                  name: "Gemius Direct Effect",
                  image: "Gemius Direct Effect",
                  role: "ADS Gemius Direct Effect",
                  connectionId: null,
                },
                {
                  name: "Google Campaign Manager",
                  image: "GoogleCampaignManager",
                  role: "ADS GoogleCampaignManager",
                  connectionId: null,
                },
                {
                  name: "Sizmek",
                  image: "Sizmek",
                  role: "ADS Sizmek",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Affilliate Advertising",
              connectors: [
                {
                  name: "Ad Service",
                  image: "adservice",
                  role: "Affilliate Advertising adservice",
                  connectionId: null,
                },

                {
                  name: "Affilinet",
                  image: "Affilinet",
                  role: "Affilliate Advertising Affilinet",
                  connectionId: null,
                },
                {
                  name: "Awin Advertiser",
                  image: "Awin Advertiser",
                  role: "Affilliate Advertising Awin Advertiser",
                  connectionId: null,
                },
                {
                  name: "Awin Publisher",
                  image: "Awin Advertiser",
                  role: "Affilliate Advertising Awin Publisher",
                  connectionId: null,
                },
                {
                  name: "Belboon",
                  image: "Belboon",
                  role: "Affilliate Advertising Belboon",
                  connectionId: null,
                },
                {
                  name: "CJ Affiliate",
                  image: "CJ Affiliate",
                  role: "Affilliate Advertising CJ Affiliate",
                  connectionId: null,
                },
                {
                  name: "Daisycon",
                  image: "Daisycon",
                  role: "Affilliate Advertising Daisycon",
                  connectionId: null,
                },
                {
                  name: "Idealo",
                  image: "Idealo",
                  role: "Affilliate Advertising Idealo",
                  connectionId: null,
                },
                {
                  name: "Ladenzeile",
                  image: "Ladenzeile",
                  role: "Affilliate Advertising Ladenzeile",
                  connectionId: null,
                },
                {
                  name: "Markplatas",
                  image: "Markplatas",
                  role: "Affilliate Advertising Markplatas",
                  connectionId: null,
                },
                {
                  name: "Partnerize",
                  image: "Partnerize",
                  role: "Affilliate Advertising Partnerize",
                  connectionId: null,
                },
                {
                  name: "Promote IQ",
                  image: "Promote IQ",
                  role: "Affilliate Advertising Promote IQ",
                  connectionId: null,
                },
                {
                  name: "Rakuten Adverter",
                  image: "Rakuten Adverter",
                  role: "Affilliate Advertising Rakuten Adverter",
                  connectionId: null,
                },
                {
                  name: "Rakuten Publisher",
                  image: "Rakuten Adverter",
                  role: "Affilliate Advertising Rakuten Publisher",
                  connectionId: null,
                },
                {
                  name: "real.de",
                  image: "real.de",
                  role: "Affilliate Advertising real.de",
                  connectionId: null,
                },
                {
                  name: "Trackonomics",
                  image: "Trackonomics",
                  role: "Affilliate Advertising Trackonomics",
                  connectionId: null,
                },
                {
                  name: "Trade Tracker",
                  image: "Trade Tracker",
                  role: "Affilliate Advertising Trade Tracker",
                  connectionId: null,
                },
                {
                  name: "Webgains",
                  image: "Webgains",
                  role: "Affilliate Advertising Webgains",
                  connectionId: null,
                },
                {
                  name: "Zanox Advertiser",
                  image: "Zanox Advertiser",
                  role: "Affilliate Advertising Zanox Advertiser",
                  connectionId: null,
                },
                {
                  name: "Zanox Publisher",
                  image: "Zanox Advertiser",
                  role: "Affilliate Advertising Zanox Publisher",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Audience Research",
              connectors: [
                {
                  name: "Audience Report",
                  image: "Audience Report",
                  role: "Audience Research Audience Report",
                  connectionId: null,
                },
                {
                  name: "Cxense",
                  image: "Cxense",
                  role: "Audience Research Cxense",
                  connectionId: null,
                },
                {
                  name: "Nielsen Ad intel",
                  image: "nielsen",
                  role: "Audience Research Nielsen Ad intel",
                  connectionId: null,
                },
                {
                  name: "Nielsen DAR",
                  image: "nielsen",
                  role: "Audience Research Nielsen DAR",
                  connectionId: null,
                },
                {
                  name: "Reppublika",
                  image: "Reppublika",
                  role: "Audience Research Reppublika",
                  connectionId: null,
                },
                {
                  name: "YouGov Brandindex",
                  image: "YouGov Brandindex",
                  role: "Audience Research YouGov Brandindex",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Brand Safety",
              connectors: [
                {
                  name: "Moat",
                  image: "Moat",
                  role: "Brand Safety Moat",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Call Analytics",
              connectors: [
                {
                  name: "Call Gear",
                  image: "Call Gear",
                  role: "Call Analytics Call Gear",
                  connectionId: null,
                },
                {
                  name: "Call Rail",
                  image: "Call Rail",
                  role: "Call Analytics Call Rail",
                  connectionId: null,
                },
                {
                  name: "Marchex",
                  image: "Marchex",
                  role: "Call Analytics Marchex",
                  connectionId: null,
                },
                {
                  name: "Response Tab",
                  image: "Response Tab",
                  role: "Call Analytics Response Tab",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Commerce",
              connectors: [
                {
                  name: "Amazon Marketplace",
                  image: "Amazon",
                  role: "Commerce Amazon Marketplace",
                  connectionId: null,
                },
                {
                  name: "Amazon Selling Partner",
                  image: "Amazon",
                  role: "Commerce Amazon Selling Partner",
                  connectionId: null,
                },
                {
                  name: "Channel Pilot",
                  image: "Channel Pilot",
                  role: "Commerce Channel Pilot",
                  connectionId: null,
                },
                {
                  name: "Dataweave",
                  image: "dataweave",
                  role: "Commerce dataweave",
                  connectionId: null,
                },
                {
                  name: "Digistore24",
                  image: "Digistore24",
                  role: "Commerce Digistore24",
                  connectionId: null,
                },
                {
                  name: "Ebay",
                  image: "Ebay",
                  role: "Commerce Ebay",
                  connectionId: null,
                },
                {
                  name: "Feed Dynamix",
                  image: "Feed Dynamix",
                  role: "Commerce Feed Dynamix",
                  connectionId: null,
                },
                {
                  name: "Google Content for Shopping",
                  image: "Google Content for Shopping",
                  role: "Commerce Google Content for Shopping",
                  connectionId: null,
                },
                {
                  name: "Magneto",
                  image: "Magneto",
                  role: "Commerce Magneto",
                  connectionId: null,
                },
                {
                  name: "Shopalyst",
                  image: "Shopalyst",
                  role: "Commerce Shopalyst",
                  connectionId: null,
                },
                {
                  name: "Shopee",
                  image: "Shopee",
                  role: "Commerce Shopee",
                  connectionId: null,
                },
                {
                  name: "Shopify",
                  image: "Shopify",
                  role: "Commerce Shopify",
                  connectionId: null,
                },
                {
                  name: "Tweakers",
                  image: "Tweakers",
                  role: "Commerce Tweakers",
                  connectionId: null,
                },
                {
                  name: "Woo commerce",
                  image: "Woo commerce",
                  role: "Commerce Woo commerce",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Data Base",
              connectors: [
                {
                  name: "Azure Synapse",
                  image: "Azure Synapse",
                  role: "Data Base Azure Synapse",
                  connectionId: null,
                },
                {
                  name: "Microsoft SQL Server",
                  image: "Microsoft SQL Server",
                  role: "Data Base Microsoft SQL Server",
                  connectionId: null,
                },
                {
                  name: "Mongo DB",
                  image: "Mongo DB",
                  role: "Data Base Mongo DB",
                  connectionId: null,
                },
                {
                  name: "My SQL",
                  image: "My SQL",
                  role: "Data Base My SQL",
                  connectionId: null,
                },
                {
                  name: "Oracle",
                  image: "Oracle",
                  role: "Data Base Oracle",
                  connectionId: null,
                },
                {
                  name: "Postgre SQl",
                  image: "Postgre SQl",
                  role: "Data Base Postgre SQl",
                  connectionId: null,
                },
                {
                  name: "Snowflake",
                  image: "Snowflake",
                  role: "Data Base Snowflake",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Data Lake",
              connectors: [
                {
                  name: "Amazon Athena",
                  image: "Amazon Athena",
                  role: "Data Lake Amazon Athena",
                  connectionId: null,
                },
                {
                  name: "Box",
                  image: "Box",
                  role: "Data Lake Box",
                  connectionId: null,
                },
                {
                  name: "Databricks",
                  image: "Databricks",
                  role: "Data Lake Databricks",
                  connectionId: null,
                },
                {
                  name: "Dropbox",
                  image: "Dropbox",
                  role: "Data Lake Dropbox",
                  connectionId: null,
                },
                {
                  name: "Egnyte EU",
                  image: "Egnyte EU",
                  role: "Data Lake Egnyte EU",
                  connectionId: null,
                },
                {
                  name: "Egnyte US",
                  image: "Egnyte EU",
                  role: "Data Lake Egnyte US",
                  connectionId: null,
                },
                {
                  name: "File Azure SAS",
                  image: "File Azure SAS",
                  role: "Data Lake File Azure SAS",
                  connectionId: null,
                },
                {
                  name: "File Azure",
                  image: "File Azure",
                  role: "Data Lake File Azure",
                  connectionId: null,
                },
                {
                  name: "File one Drive",
                  image: "File one Drive",
                  role: "Data Lake File one Drive",
                  connectionId: null,
                },
                {
                  name: "File",
                  image: "File",
                  role: "Data Lake File",
                  connectionId: null,
                },
                {
                  name: "Google Drive",
                  image: "google-drive",
                  role: "Data Lake Google Drive",
                  connectionId: null,
                },
                {
                  name: "Google Sheets",
                  image: "Google_Sheets",
                  role: "Data Lake Google Sheets",
                  connectionId: null,
                },
                {
                  name: "Hadoop",
                  image: "Hadoop",
                  role: "Data Lake Hadoop",
                  connectionId: null,
                },
                {
                  name: "S3 Connection",
                  image: "S3 Connection",
                  role: "Data Lake S3 Connection",
                  connectionId: null,
                },
                {
                  name: "SFTP Public Key",
                  image: "File",
                  role: "Data Lake SFTP Public Key",
                  connectionId: null,
                },
                {
                  name: "Sharepoint Online",
                  image: "Sharepoint Online",
                  role: "Data Lake Sharepoint Online",
                  connectionId: null,
                },
                {
                  name: "WebDAV",
                  image: "File",
                  role: "Data Lake WebDAV",
                  connectionId: null,
                },
              ],
            },
            {
              title: "DMP",
              connectors: [
                {
                  name: "Adform DMP OAuth2",
                  image: "Adform DMP OAuth2",
                  role: "DMP Adform DMP OAuth2",
                  connectionId: null,
                },
                {
                  name: "Adobe Experience PLatform",
                  image: "Adobe Experience PLatform",
                  role: "DMP Adobe Experience PLatform",
                  connectionId: null,
                },
                {
                  name: "Audience Studio",
                  image: "Audience Studio",
                  role: "DMP Audience Studio",
                  connectionId: null,
                },
              ],
            },
            {
              title: "DSP",
              connectors: [
                {
                  name: "Active Agent",
                  image: "Active Agent",
                  role: "DSP Active Agent",
                  connectionId: null,
                },
                {
                  name: "Adman",
                  image: "Adman",
                  role: "DSP Adman",
                  connectionId: null,
                },
                {
                  name: "Adobe Advertising Cloud",
                  image: "Adobe Advertising Cloud",
                  role: "DSP Adobe Advertising Cloud",
                  connectionId: null,
                },
                {
                  name: "AdRiver",
                  image: "AdRiver",
                  role: "DSP AdRiver",
                  connectionId: null,
                },
                {
                  name: "AdRoll",
                  image: "AdRoll",
                  role: "DSP AdRoll",
                  connectionId: null,
                },
                {
                  name: "Amazon DSP",
                  image: "Amazon",
                  role: "DSP Amazon DSP",
                  connectionId: null,
                },
                {
                  name: "Avocet",
                  image: "Avocet",
                  role: "DSP Avocet",
                  connectionId: null,
                },
                {
                  name: "Beeswax",
                  image: "Beeswax",
                  role: "DSP Beeswax",
                  connectionId: null,
                },
                {
                  name: "Celtra",
                  image: "Celtra",
                  role: "DSP Celtra",
                  connectionId: null,
                },
                {
                  name: "Cision",
                  image: "Cision",
                  role: "DSP Cision",
                  connectionId: null,
                },
                {
                  name: "Criteo Marketing Solutions",
                  image: "Criteo Marketing Solutions",
                  role: "DSP Criteo Marketing Solutions",
                  connectionId: null,
                },
                {
                  name: "Criteo Marketing",
                  image: "Criteo Marketing Solutions",
                  role: "DSP Criteo Marketing",
                  connectionId: null,
                },
                {
                  name: "Google Display & Video 360",
                  image: "DV360",
                  role: "DSP Google Display & Video 360",
                  connectionId: null,
                },
                {
                  name: "Hudson MX",
                  image: "Hudson MX",
                  role: "DSP Hudson MX",
                  connectionId: null,
                },
                {
                  name: "Jivox",
                  image: "Jivox",
                  role: "DSP Jivox",
                  connectionId: null,
                },
                {
                  name: "Lotame",
                  image: "Lotame",
                  role: "DSP Lotame",
                  connectionId: null,
                },
                {
                  name: "Mediamath",
                  image: "Mediamath",
                  role: "DSP Mediamath",
                  connectionId: null,
                },
                {
                  name: "My Taerget",
                  image: "My Taerget",
                  role: "DSP My Taerget",
                  connectionId: null,
                },
                {
                  name: "Outmoove",
                  image: "Outmoove",
                  role: "DSP Outmoove",
                  connectionId: null,
                },
                {
                  name: "Red Pineapple Media",
                  image: "Red Pineapple Media",
                  role: "DSP Red Pineapple Media",
                  connectionId: null,
                },
                {
                  name: "RTB House",
                  image: "RTB House",
                  role: "DSP RTB House",
                  connectionId: null,
                },
                {
                  name: "Stack Adapt",
                  image: "Stack Adapt",
                  role: "DSP Stack Adapt",
                  connectionId: null,
                },
                {
                  name: "Teads Ad Manager",
                  image: "Teads Ad Manager",
                  role: "DSP Teads Ad Manager",
                  connectionId: null,
                },
                {
                  name: "Teads Analytics",
                  image: "Teads Ad Manager",
                  role: "DSP Teads Analytics",
                  connectionId: null,
                },
                {
                  name: "The Trade Desk",
                  image: "The Trade Desk",
                  role: "DSP The Trade Desk",
                  connectionId: null,
                },
                {
                  name: "Verizon Media DSP",
                  image: "Verizon Media DSP",
                  role: "DSP Verizon Media DSP",
                  connectionId: null,
                },
                {
                  name: "Vistar Media",
                  image: "Vistar Media",
                  role: "DSP Vistar Media",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Generic",
              connectors: [
                {
                  name: "Google Big Query (Oauth)",
                  image: "genericGoogle",
                  role: "Generic Google Big Query (Oauth)",
                  connectionId: null,
                },
                {
                  name: "Google BigQuery (Service)",
                  image: "genericGoogle",
                  role: "Generic Google BigQuery (Service)",
                  connectionId: null,
                },
                {
                  name: "Google Cloud Storage",
                  image: "Google Cloud Storage",
                  role: "Generic Google Cloud Storage",
                  connectionId: null,
                },
                {
                  name: "Microsoft SQL Server",
                  image: "Microsoft SQL Server",
                  role: "Generic Microsoft SQL Server",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Mobile",
              connectors: [
                {
                  name: "Adjust",
                  image: "Adjust",
                  role: "Mobile Adjust",
                  connectionId: null,
                },
                {
                  name: "APP Annie",
                  image: "APP Annie",
                  role: "Mobile APP Annie",
                  connectionId: null,
                },
                {
                  name: "App Follow",
                  image: "App Follow",
                  role: "Mobile App Follow",
                  connectionId: null,
                },
                {
                  name: "App Figures",
                  image: "appfigures",
                  role: "Mobile appfigures",
                  connectionId: null,
                },
                {
                  name: "AppLovin",
                  image: "AppLovin",
                  role: "Mobile AppLovin",
                  connectionId: null,
                },
                {
                  name: "Apps Flyer",
                  image: "appsFlyer",
                  role: "Mobile Apps Flyer",
                  connectionId: null,
                },
                {
                  name: "apptweak",
                  image: "apptweak",
                  role: "Mobile apptweak",
                  connectionId: null,
                },
                {
                  name: "Branch Querry API",
                  image: "Branch Querry API",
                  role: "Mobile Branch Querry API",
                  connectionId: null,
                },
                {
                  name: "Branch",
                  image: "Branch Querry API",
                  role: "Mobile Branch",
                  connectionId: null,
                },
                {
                  name: "Fyber",
                  image: "Fyber",
                  role: "Mobile Fyber",
                  connectionId: null,
                },
                {
                  name: "Iron Source",
                  image: "Iron Source",
                  role: "Mobile Iron Source",
                  connectionId: null,
                },
                {
                  name: "Kochava",
                  image: "Kochava",
                  role: "Mobile Kochava",
                  connectionId: null,
                },
                {
                  name: "NativeX",
                  image: "NativeX",
                  role: "Mobile NativeX",
                  connectionId: null,
                },
                {
                  name: "Priori data",
                  image: "Priori data",
                  role: "Mobile Priori data",
                  connectionId: null,
                },
                {
                  name: "SPlicky",
                  image: "SPlicky",
                  role: "Mobile SPlicky",
                  connectionId: null,
                },
                {
                  name: "tune-branch",
                  image: "tune-branch",
                  role: "Mobile tune-branch",
                  connectionId: null,
                },
                {
                  name: "Uberall",
                  image: "Uberall",
                  role: "Mobile Uberall",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Native Advertising",
              connectors: [
                {
                  name: "Affilinet",
                  image: "Affilinet",
                  role: "NativeAdvertising Affilinet",
                  connectionId: null,
                },
                {
                  name: "Ligatus",
                  image: "Ligatus",
                  role: "NativeAdvertising Ligatus",
                  connectionId: null,
                },
                {
                  name: "Outbrain",
                  image: "Outbrain",
                  role: "NativeAdvertising Outbrain",
                  connectionId: null,
                },
                {
                  name: "Plista (GraphQL)",
                  image: "Plista (GraphQL)",
                  role: "NativeAdvertising Plista (GraphQL)",
                  connectionId: null,
                },
                {
                  name: "Plista",
                  image: "Plista",
                  role: "NativeAdvertising Plista",
                  connectionId: null,
                },
                {
                  name: "Taboola",
                  image: "Taboola",
                  role: "NativeAdvertising Taboola",
                  connectionId: null,
                },
                {
                  name: "Verizon Media",
                  image: "Verizon Media",
                  role: "NativeAdvertising Verizon Media",
                  connectionId: null,
                },
                {
                  name: "Zemanta",
                  image: "Zemanta",
                  role: "NativeAdvertising Zemanta",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Others",
              connectors: [
                {
                  name: "Adbook+",
                  image: "Adbook+",
                  role: "Others Adbook+",
                  connectionId: null,
                },
                {
                  name: "Bright Talk",
                  image: "Bright Talk",
                  role: "Others Bright Talk",
                  connectionId: null,
                },
                {
                  name: "Bynder",
                  image: "Bynder",
                  role: "Others Bynder",
                  connectionId: null,
                },
                {
                  name: "Drift",
                  image: "Drift",
                  role: "Others Drift",
                  connectionId: null,
                },
                {
                  name: "Google Calender",
                  image: "Google Calender",
                  role: "Others Google Calender",
                  connectionId: null,
                },
                {
                  name: "GoTo Webinar",
                  image: "GoTo Webinar",
                  role: "Others GoTo Webinar",
                  connectionId: null,
                },
                {
                  name: "Innovid",
                  image: "Innovid",
                  role: "Others Innovid",
                  connectionId: null,
                },
                {
                  name: "Invoca",
                  image: "Invoca",
                  role: "Others Invoca",
                  connectionId: null,
                },
                {
                  name: "Kenshoo",
                  image: "Kenshoo",
                  role: "Others Kenshoo",
                  connectionId: null,
                },
                {
                  name: "Mailgun",
                  image: "Mailgun",
                  role: "Others Mailgun",
                  connectionId: null,
                },
                {
                  name: "Media Wizard",
                  image: "Media Wizard",
                  role: "Others Media Wizard",
                  connectionId: null,
                },
                {
                  name: "Norstat",
                  image: "Norstat",
                  role: "Others Norstat",
                  connectionId: null,
                },
                {
                  name: "Podigee",
                  image: "Podigee",
                  role: "Others Podigee",
                  connectionId: null,
                },
                {
                  name: "Spotwatch",
                  image: "Spotwatch",
                  role: "Others Spotwatch",
                  connectionId: null,
                },
                {
                  name: "Strpe",
                  image: "Strpe",
                  role: "Others Strpe",
                  connectionId: null,
                },
                {
                  name: "TXGB",
                  image: "TXGB",
                  role: "Others TXGB",
                  connectionId: null,
                },
                {
                  name: "Unbounce",
                  image: "Unbounce",
                  role: "Others Unbounce",
                  connectionId: null,
                },
                {
                  name: "Usabilla",
                  image: "Usabilla",
                  role: "Others Usabilla",
                  connectionId: null,
                },
                {
                  name: "Web Connect (Bearer)",
                  image: "Web Connect",
                  role: "Others Web Connect (Bearer)",
                  connectionId: null,
                },
                {
                  name: "Web Connect (Oauth)",
                  image: "Web Connect",
                  role: "Others Web Connect (Oauth)",
                  connectionId: null,
                },
                {
                  name: "Web Connect (Oauth2)",
                  image: "Web Connect",
                  role: "Others Web Connect (Oauth2)",
                  connectionId: null,
                },
                {
                  name: "Web Connect",
                  image: "Web Connect",
                  role: "Others Web Connect",
                  connectionId: null,
                },
                {
                  name: "Zendesk",
                  image: "Zendesk",
                  role: "Others Zendesk",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Search Advertising",
              connectors: [
                {
                  name: "360.sn",
                  image: "360.sn",
                  role: "Search Advertising 360.sn",
                  connectionId: null,
                },
                {
                  name: "Amazon Advertising",
                  image: "Amazon",
                  role: "Search Advertising Amazon Advertising",
                  connectionId: null,
                },
                {
                  name: "Apple Search Ads",
                  image: "Apple Search Ads",
                  role: "Search Advertising Apple Search Ads",
                  connectionId: null,
                },
                {
                  name: "Googel Adwords",
                  image: "google-ad",
                  role: "Search Advertising Googel Adwords",
                  connectionId: null,
                },
                {
                  name: "Google Ads New",
                  image: "google-ad",
                  role: "Search Advertising Google Ads New",
                  connectionId: null,
                },
                {
                  name: "Google Keyword Planner",
                  image: "google-ad",
                  role: "Search Advertising Google Keyword Planner",
                  connectionId: null,
                },
                {
                  name: "Google Search ads 360",
                  image: "Google Search ads 360",
                  role: "Search Advertising Google Search ads 360",
                  connectionId: null,
                },
                {
                  name: "indeed sponsored jobs",
                  image: "indeed sponsored jobs",
                  role: "Search Advertising indeed sponsored jobs",
                  connectionId: null,
                },
                {
                  name: "Microsoft Ads",
                  image: "MicrosoftAds",
                  role: "Search Advertising Microsoft Ads",
                  connectionId: null,
                },
                {
                  name: "The Search Monitor",
                  image: "The Search Monitor",
                  role: "Search Advertising The Search Monitor",
                  connectionId: null,
                },
                {
                  name: "Yabdex",
                  image: "Yabdex",
                  role: "Search Advertising Yabdex",
                  connectionId: null,
                },
                {
                  name: "Yahoo Japan Ads",
                  image: "Yahoo Japan Ads",
                  role: "Search Advertising Yahoo Japan Ads",
                  connectionId: null,
                },
                {
                  name: "Yello Pages",
                  image: "Yello Pages",
                  role: "Search Advertising Yello Pages",
                  connectionId: null,
                },
                {
                  name: "Yelp Ads",
                  image: "Yelp Ads",
                  role: "Search Advertising Yelp Ads",
                  connectionId: null,
                },
              ],
            },
            {
              title: "SEO",
              connectors: [
                {
                  name: "AccuRanker",
                  image: "AccuRanker",
                  role: "SEO AccuRanker",
                  connectionId: null,
                },
                {
                  name: "Advanced Web Ranking",
                  image: "Advanced Web Ranking",
                  role: "SEO Advanced Web Ranking",
                  connectionId: null,
                },
                {
                  name: "Bing Webmaster",
                  image: "Bing Webmaster",
                  role: "SEO Bing Webmaster",
                  connectionId: null,
                },
                {
                  name: "Brighedge",
                  image: "Brighedge",
                  role: "SEO Brighedge",
                  connectionId: null,
                },
                {
                  name: "Calibre",
                  image: "Calibre",
                  role: "SEO Calibre",
                  connectionId: null,
                },
                {
                  name: "Datametrics",
                  image: "Datametrics",
                  role: "SEO Datametrics",
                  connectionId: null,
                },
                {
                  name: "Google Search Console",
                  image: "Google Search Console",
                  role: "SEO Google Search Console",
                  connectionId: null,
                },
                {
                  name: "Keyword Tool",
                  image: "Keyword Tool",
                  role: "SEO Keyword Tool",
                  connectionId: null,
                },
                {
                  name: "Link",
                  image: "Link",
                  role: "SEO Link",
                  connectionId: null,
                },
                {
                  name: "MoZ",
                  image: "MoZ",
                  role: "SEO MoZ",
                  connectionId: null,
                },
                {
                  name: "Powerlinks",
                  image: "Powerlinks",
                  role: "SEO Powerlinks",
                  connectionId: null,
                },
                {
                  name: "rank Ranger",
                  image: "rank Ranger",
                  role: "SEO rank Ranger",
                  connectionId: null,
                },
                {
                  name: "Search metrics",
                  image: "Search metrics",
                  role: "SEO Search metrics",
                  connectionId: null,
                },
                {
                  name: "Semrush",
                  image: "Semrush",
                  role: "SEO Semrush",
                  connectionId: null,
                },
                {
                  name: "Sistrix",
                  image: "Sistrix",
                  role: "SEO Sistrix",
                  connectionId: null,
                },
                {
                  name: "STAT",
                  image: "STAT",
                  role: "SEO STAT",
                  connectionId: null,
                },
                {
                  name: "Web CEO",
                  image: "Web CEO",
                  role: "SEO Web CEO",
                  connectionId: null,
                },
                {
                  name: "Yext",
                  image: "Yext",
                  role: "SEO Yext",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Social Advertising",
              connectors: [
                {
                  name: "Facebook Ads Connect",
                  image: "Facebook",
                  role: "Social Advertising Facebook Ads Connect",
                  connectionId: null,
                },
                {
                  name: "Facebook Public Connection",
                  image: "Facebook",
                  role: "Social Advertising Facebook Public Connection",
                  connectionId: null,
                },
                {
                  name: "Linkedin Ads",
                  image: "LinkedIn",
                  role: "Social Advertising Linkedin Ads",
                  connectionId: null,
                },
                {
                  name: "MakeMeReach",
                  image: "MakeMeReach",
                  role: "Social Advertising MakeMeReach",
                  connectionId: null,
                },
                {
                  name: "Nexta",
                  image: "Nexta",
                  role: "Social Advertising Nexta",
                  connectionId: null,
                },
                {
                  name: "Pinterest",
                  image: "Pinterest",
                  role: "Social Advertising Pinterest",
                  connectionId: null,
                },
                {
                  name: "Reddit Ads",
                  image: "Reddit Ads",
                  role: "Social Advertising Reddit Ads",
                  connectionId: null,
                },
                {
                  name: "Snapchat",
                  image: "Snapchat",
                  role: "Social Advertising Snapchat",
                  connectionId: null,
                },
                {
                  name: "Tiktok Ads",
                  image: "Tiktok Ads",
                  role: "Social Advertising Tiktok Ads",
                  connectionId: null,
                },
                {
                  name: "Twitter Ads",
                  image: "twitter",
                  role: "Social Advertising twitter Ads",
                  connectionId: null,
                },
                {
                  name: "VK ads",
                  image: "VK ads",
                  role: "Social Advertising VK ads",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Social Analytics",
              connectors: [
                {
                  name: "Adalyser",
                  image: "Adalyser",
                  role: "Social Analytics Adalyser",
                  connectionId: null,
                },
                {
                  name: "Awario",
                  image: "Awario",
                  role: "Social Analytics Awario",
                  connectionId: null,
                },
                {
                  name: "Brandwatch",
                  image: "brandWatch",
                  role: "Social Analytics Brandwatch",
                  connectionId: null,
                },
                {
                  name: "Facebook Page Insights",
                  image: "Facebook",
                  role: "Social Analytics Facebook Page Insights",
                  connectionId: null,
                },
                {
                  name: "Google My Business",
                  image: "Google My Business",
                  role: "Social Analytics Google My Business",
                  connectionId: null,
                },
                {
                  name: "Instagram Basic Dislplay",
                  image: "Instagram",
                  role: "Social Analytics Instagram Basic Dislplay",
                  connectionId: null,
                },
                {
                  name: "Instagram Business",
                  image: "Instagram Business",
                  role: "Social Analytics Instagram Business",
                  connectionId: null,
                },
                {
                  name: "Keyhole",
                  image: "keyHole",
                  role: "Social Analytics Keyhole",
                  connectionId: null,
                },
                {
                  name: "Linkedin organisations and brands",
                  image: "LinkedIn",
                  role: "Social Analytics Linkedin organisations and brands",
                  connectionId: null,
                },
                {
                  name: "Linkfluence Radarly",
                  image: "Linkfluence Radarly",
                  role: "Social Analytics Linkfluence Radarly",
                  connectionId: null,
                },
                {
                  name: "netbase",
                  image: "netbase",
                  role: "Social Analytics netbase",
                  connectionId: null,
                },
                {
                  name: "Socialbakers",
                  image: "Socialbakers",
                  role: "Social Analytics Socialbakers",
                  connectionId: null,
                },
                {
                  name: "Sprinklr",
                  image: "Sprinklr",
                  role: "Social Analytics Sprinklr",
                  connectionId: null,
                },
                {
                  name: "Sprinklr Prod2",
                  image: "Sprinklr",
                  role: "Social Analytics Sprinklr Prod2",
                  connectionId: null,
                },
                {
                  name: "Synthesio",
                  image: "Synthesio",
                  role: "Social Analytics Synthesio",
                  connectionId: null,
                },
                {
                  name: "Talkwalker",
                  image: "Talkwalker",
                  role: "Social Analytics Talkwalker",
                  connectionId: null,
                },
                {
                  name: "Twitch",
                  image: "Twitch",
                  role: "Social Analytics Twitch",
                  connectionId: null,
                },
                {
                  name: "Unmetric",
                  image: "Unmetric",
                  role: "Social Analytics Unmetric",
                  connectionId: null,
                },
                {
                  name: "Youtube Analytics",
                  image: "youtube",
                  role: "Social Analytics Youtube Analytics",
                  connectionId: null,
                },
                {
                  name: "Youtube public communications",
                  image: "youtube",
                  role: "Social Analytics Youtube public communications",
                  connectionId: null,
                },
                {
                  name: "Youtube Public",
                  image: "youtube",
                  role: "Social Analytics Youtube Public",
                  connectionId: null,
                },
              ],
            },
            {
              title: "SSP",
              connectors: [
                {
                  name: "Adform Seller",
                  image: "Adform Seller",
                  role: "SSP Adform Seller",
                  connectionId: null,
                },
                {
                  name: "Googlee Ad manager",
                  image: "Googlee Ad manager",
                  role: "SSP Googlee Ad manager",
                  connectionId: null,
                },
                {
                  name: "Media.et Neo tech API",
                  image: "Media.et Neo tech API",
                  role: "SSP Media.et Neo tech API",
                  connectionId: null,
                },
                {
                  name: "Media.et pub console",
                  image: "Media.et Neo tech API",
                  role: "SSP Media.et pub console",
                  connectionId: null,
                },
                {
                  name: "Pubmatic",
                  image: "Pubmatic",
                  role: "SSP Pubmatic",
                  connectionId: null,
                },
                {
                  name: "Sovrn",
                  image: "Sovrn",
                  role: "SSP Sovrn",
                  connectionId: null,
                },
                {
                  name: "Verizon Media",
                  image: "Verizon Media",
                  role: "SSP Verizon Media",
                  connectionId: null,
                },
                {
                  name: "xandr",
                  image: "xandr",
                  role: "SSP xandr",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Time Traker",
              connectors: [
                {
                  name: "Clockify",
                  image: "Clockify",
                  role: "Time Traker Clockify",
                  connectionId: null,
                },
                {
                  name: "Harvest",
                  image: "Harvest",
                  role: "Time Traker Harvest",
                  connectionId: null,
                },
                {
                  name: "Yanomo",
                  image: "Yanomo",
                  role: "Time Traker Yanomo",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Weather",
              connectors: [
                {
                  name: "Open Weather Map",
                  image: "Open Weather Map",
                  role: "Weather Open Weather Map",
                  connectionId: null,
                },
                {
                  name: "Weather Stack",
                  image: "Weather Stack",
                  role: "Weather Weather Stack",
                  connectionId: null,
                },
              ],
            },
          ],
        },
        {
          category: "Main Line Media",
          tabContent: [
            {
              title: "Television",
              connectors: [
                { name: "Nielsen TV", image: "nielsen", role: "nielsenTv" },
                { name: "Barc", image: "Barc", role: "barc" },
                {
                  name: "Google Drive",
                  image: "google-drive",
                  role: "google-driveasa",
                },
              ],
            },
            {
              title: "Competition",
              connectors: [
                {
                  name: "Nielsen",
                  image: "nielsen",
                  role: "nielsen Competition",
                },
                { name: "Kantar", image: "kantar", role: "kantarCompetition" },
                {
                  name: "Google Drive",
                  image: "google-drive",
                  role: "google-driveasa Competition",
                },
              ],
            },
            {
              title: "Radio",
              connectors: [
                { name: "Kantar", image: "kantar", role: "kantarRadio" },
                {
                  name: "Google Drive",
                  image: "google-drive",
                  role: "google-driveasaRadio",
                },
                { name: "Map", image: "map", role: "map radio" },
              ],
            },
            {
              title: "Print",
              connectors: [
                { name: "Kantar", image: "kantar", role: "kantarPrint" },
                {
                  name: "Google Drive",
                  image: "google-drive",
                  role: "google-driveasaPrint",
                },
                { name: "Map", image: "map", role: "map print" },
                { name: "IRS", image: "irs", role: "irs print" },
              ],
            },
          ],
        },
        {
          category: "Sponsorship",
          tabContent: [
            {
              title: "Sponsorship",
              connectors: [
                { name: "Gum Gum", image: "gumgum", role: "gumgum" },
                { name: "Hookit", image: "hookit", role: "hookit" },
                {
                  name: "Nielsen Sports",
                  image: "nielsen",
                  role: "nielsenSports",
                },
                { name: "Melt Water", image: "meltWater", role: "meltWaterSp" },
              ],
            },
          ],
        },
        {
          category: "Public Relation",
          tabContent: [
            {
              title: "Public Reaction",
              connectors: [
                { name: "Cision", image: "cision", role: "cision" },
                {
                  name: "Melt Water",
                  image: "meltWater",
                  role: "meltWaterPublic",
                },
              ],
            },
            {
              title: "Social Listening",
              connectors: [
                {
                  name: "Melt Water",
                  image: "meltWater",
                  role: "meltWaterSocial",
                },
                {
                  name: "Key Hole",
                  image: "keyHole",
                  role: "keyHole",
                },
                {
                  name: "Brand Watch",
                  image: "brandWatch",
                  role: "brandWatch",
                },
              ],
            },
          ],
        },
        {
          category: "OOH",
          tabContent: [
            {
              title: "OOH",
              connectors: [
                { name: "SignKick", image: "signKick", role: "SignKick OOH" },
                { name: "Geopath", image: "geopath", role: "Geopath OOH" },
                {
                  name: "Google Drive",
                  image: "google-drive",
                  role: "google-driveasa OOH",
                },
                { name: "Times OOH", image: "timesooh", role: "Times OOH" },
              ],
            },
          ],
        },
        // {
        //   category: "KPI Data",
        //   tabContent: [
        //     {
        //       title: "CPG",
        //       connectors: [
        //         {
        //           name: "Nielsen Retail Measurement",
        //           image: "nielsen",
        //           role: "retailNielsen",
        //         },
        //         {
        //           name: "Conumer Panel",
        //           image: "Consumer Panel",
        //           role: "consumerPannel",
        //         },
        //       ],
        //     },
        //     {
        //       title: "Transactional Data",
        //       connectors: [
        //         {
        //           name: "Super Panel(12 Mn, 9Yrs)",
        //           image: "Consumer Panel",
        //           role: "superPannel",
        //         },
        //         {
        //           name: "Panel F(8 Mn, 5Yrs)",
        //           image: "Consumer Panel",
        //           role: "pannelF",
        //         },
        //       ],
        //     },
        //     {
        //       title: "Location",
        //       connectors: [
        //         { name: "4 Square", image: "Foursquare", role: "4Square" },
        //         { name: "Lifesight", image: "lifesight", role: "lifesight" },
        //       ],
        //     },
        //     {
        //       title: "E-Commerce",
        //       connectors: [
        //         { name: "Amazon", image: "Amazon", role: "Amazone" },
        //         {
        //           name: "Panel M(20Mn, 6Yrs)",
        //           image: "Consumer Panel",
        //           role: "pannelM",
        //         },
        //       ],
        //     },
        //   ],
        // },
        {
          category: "CRM",
          tabContent: [
            {
              title: "CRM",
              connectors: [
                {
                  name: "Active Campaign",
                  image: "Active Campaign",
                  role: "CRM Active Campaign",
                  connectionId: null,
                },
                {
                  name: "Braze",
                  image: "Braze",
                  role: "CRM Braze",
                  connectionId: null,
                },
                {
                  name: "Campaign Monitor",
                  image: "Campaign Monitor",
                  role: "CRM Campaign Monitor",
                  connectionId: null,
                },
                {
                  name: "Cordial",
                  image: "Cordial",
                  role: "CRM Cordial",
                  connectionId: null,
                },
                {
                  name: "Emarsys",
                  image: "Emarsys",
                  role: "CRM Emarsys",
                  connectionId: null,
                },
                {
                  name: "Evalanche",
                  image: "Evalanche",
                  role: "CRM Evalanche",
                  connectionId: null,
                },
                {
                  name: "Hubspot Destination",
                  image: "Hubspot Destination",
                  role: "CRM Hubspot Destination",
                  connectionId: null,
                },
                {
                  name: "Hubspot",
                  image: "Hubspot Destination",
                  role: "CRM Hubspot",
                  connectionId: null,
                },
                {
                  name: "Iterable",
                  image: "Iterable",
                  role: "CRM Iterable",
                  connectionId: null,
                },
                {
                  name: "Klaviyo",
                  image: "Klaviyo",
                  role: "CRM Klaviyo",
                  connectionId: null,
                },
                {
                  name: "Mailchimp",
                  image: "Mailchimp",
                  role: "CRM Mailchimp",
                  connectionId: null,
                },
                {
                  name: "Mailing Work",
                  image: "Mailing Work",
                  role: "CRM Mailing Work",
                  connectionId: null,
                },
                {
                  name: "Mailworx",
                  image: "Mailworx",
                  role: "CRM Mailworx",
                  connectionId: null,
                },
                {
                  name: "Marketo",
                  image: "Marketo",
                  role: "CRM Marketo",
                  connectionId: null,
                },
                {
                  name: "Microsoft Dynamics",
                  image: "Microsoft Dynamics",
                  role: "CRM Microsoft Dynamics",
                  connectionId: null,
                },
                {
                  name: "Piano",
                  image: "Piano",
                  role: "CRM Piano",
                  connectionId: null,
                },
                {
                  name: "Salesforce Marketing Cloud",
                  image: "salesforce",
                  role: "CRM Salesforce Marketing Cloud",
                  connectionId: null,
                },
                {
                  name: "Salesforce",
                  image: "salesforce",
                  role: "CRM Salesforce",
                  connectionId: null,
                },
                {
                  name: "Sendinblue",
                  image: "Sendinblue",
                  role: "CRM Sendinblue",
                  connectionId: null,
                },
                {
                  name: "Sharpspring",
                  image: "Sharpspring",
                  role: "CRM Sharpspring",
                  connectionId: null,
                },
                {
                  name: "Teamleader",
                  image: "Teamleader",
                  role: "CRM Teamleader",
                  connectionId: null,
                },
                {
                  name: "Zendesk Sell",
                  image: "Zendesk Sell",
                  role: "CRM Zendesk Sell",
                  connectionId: null,
                },

                {
                  name: "Microsoft Dynamics",
                  image: "microsoft",
                  role: "microsoftdynamics",
                },
                {
                  name: "Google Drive",
                  image: "google-drive",
                  role: "googledriveCRM",
                },
                {
                  name: "Sales Force",
                  image: "salesforce",
                  role: "salesForce",
                },
                {
                  name: "Marketo",
                  image: "salesforce",
                  role: "marketo",
                },
              ],
            },
            {
              title: "Financial Management",
              connectors: [
                { name: "Netsuite", image: "netsuite", role: "netsuite" },
              ],
            },
          ],
        },
        {
          category: "Additional Details",
          tabContent: [],
        },
      ],
    };
  },
  computed: {
    isDisable() {
      if (
        this.selectedMarket !== "Select" &&
        this.selectedKPI !== "Select" &&
        this.selectedMarket !== "" &&
        this.selectedKPI !== "" &&
        this.checkObj(this.selectedKPI) !== 0 &&
        this.checkObj(this.selectedMarket) !== 0
      ) {
        return false;
      }
      return true;
    },
    checkObj() {
      return (data) => {
        return Object.keys(data).length;
      };
    },
    disableConnectors() {
      if (this.activeFrom === 1) {
        if (this.selectedConnectors.length > 0) {
          return false;
        }
      } else if (this.activeFrom === 2) {
        return false;
      }
      return true;
    },
  },
  methods: {
    doneProgress() {
      this.$router.push("/demo/in/platform/models");
    },
    createPlan() {
      console.log("create Plan");
      this.$refs.solutionModal.open();
    },
    selectedKPi(e) {
      if (this.selectedKPI != e) {
        this.selectedKPI = e;
        console.log(e);
      }
    },
    selectMarket(e) {
      if (e === this.selectedMarket || e === "Select") {
        return;
      }
      sessionStorage.setItem("kpiMarketIndia", JSON.stringify(e));
      this.selectedMarket = e;
    },
    orderSubCategoryMethod() {
      for (var i = 0; i < this.categorizedSelectedConnectors.length; i++) {
        var tabContent = [];
        for (
          var j = 0;
          j < this.categorizedSelectedConnectors[i].tabContent.length;
          j++
        ) {
          if (
            tabContent.find(
              (subTitle) =>
                subTitle.title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
            ) === undefined
          ) {
            tabContent.push({
              title: this.categorizedSelectedConnectors[i].tabContent[j].title,
              connectors: [
                {
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image:
                    this.categorizedSelectedConnectors[i].tabContent[j]
                      .className,
                },
              ],
            });
          } else {
            for (var k = 0; k < tabContent.length; k++) {
              if (
                tabContent[k].title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
              ) {
                tabContent[k].connectors.push({
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image:
                    this.categorizedSelectedConnectors[i].tabContent[j]
                      .className,
                });
              }
            }
          }
          if (
            j ===
            this.categorizedSelectedConnectors[i].tabContent.length - 1
          ) {
            this.categorizedSelectedConnectors[i].tabContent = tabContent;
          }
        }
        if (i === this.categorizedSelectedConnectors.length - 1) {
          this.activeTab = this.categorizedSelectedConnectors[0].category;
        }
      }
    },
    nextToSource() {
      if (this.activeFrom === 2) {
        this.activeFrom = 3;

        return;
      }
      this.activeFrom = 2;
      this.categorizedSelectedConnectors = [];
      for (var i = 0; i < this.selectedConnectors.length; i++) {
        if (
          this.categorizedSelectedConnectors.find(
            (obj) => obj.category === this.selectedConnectors[i].category
          ) === undefined
        ) {
          this.categorizedSelectedConnectors.push({
            category: this.selectedConnectors[i].category,
            tabContent: [this.selectedConnectors[i]],
          });
        } else {
          for (var j = 0; j < this.categorizedSelectedConnectors.length; j++) {
            if (
              this.categorizedSelectedConnectors[j].category ===
              this.selectedConnectors[i].category
            ) {
              this.categorizedSelectedConnectors[j].tabContent.push(
                this.selectedConnectors[i]
              );
            }
          }
        }
        if (i === this.selectedConnectors.length - 1) {
          this.orderSubCategoryMethod();
        }
      }
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    addActiveTab() {},
    addPlatform(obj) {
      var platformobj = obj.obj;
      var title = obj.title;
      if (
        this.selectedConnectors.find(
          (connector) => connector.role === platformobj.role
        ) != undefined
      ) {
        for (var i = 0; i < this.selectedConnectors.length; i++) {
          if (this.selectedConnectors[i].role == platformobj.role) {
            this.selectedConnectors.splice(i, 1);
          }
        }
      } else {
        this.selectedConnectors.push({
          title: title,
          category: this.activeTab,
          connectorName: platformobj.name,
          className: platformobj.image,
          role: platformobj.role,
        });
      }
    },
  },
  created() {},
};
</script>
<style scoped>
.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}
</style>
